<template>
  <div>
    <ul>
      <div id="index8">
        <li v-for="(item, index) in listData" :key="index">
          <div @click="jumpToDetailsPage(item)" :style="list[Math.floor(Math.random() * list.length)]">
            <span style="font-weight: bold">{{ item.thName }}</span>
          </div>
        </li>
      </div>
    </ul>
    <el-dialog
        title="口令密码"
        :visible.sync="accessVisible"
        width="80%">
      <div class="demo-input-suffix">
        <el-input
            placeholder="嘿, 偷窥秘密得有口令才能哦...."
            v-model="accessVal"
            clearable
            show-password
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="accessVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmPassword">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import m3u8 from "@/request/hole";

export default {
  name: "treeHoleGroup",
  data() {
    return {
      listData: [],
      askIndex: new m3u8(),
      accessVisible: false,
      accessVal: '',
      currentHole: null,
      list: [
        'background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);',
        'background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);',
        'background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);',
        'background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);',
        'background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);',
        'background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%);',
        'background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);',
        'background-image: linear-gradient(to top, #9890e3 0%, #b1f4cf 100%);'
      ]
    }
  },
  methods: {
    bgColorFor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color;
    },
    jumpToDetailsPage(item) {
      if (item.thIsPrivate) {
        this.accessVisible = true;
        this.currentHole = item;
      } else {
        this.$router.push({
          name: 'treeHoleMsg',
          params: {
            thId: item.thCode
          }
        })
      }
    },
    /**
     * 确认口令
     */
    confirmPassword() {
      if (this.currentHole.thPwd === this.accessVal) {
        this.$router.push({
          name: 'treeHoleMsg',
          params: {
            thId: this.currentHole.thCode
          }
        })
      } else {
        this.$message.error('宝, 错了哦! ');
      }
    }
  },
  created() {
    this.askIndex.getTreeHoleS().then(res => {
      this.listData = res.msg
    });
  }
}
</script>

<style scoped>
ul {
  padding-left: 0px;
}

li {
  margin-top: 5px;
  list-style: none;
}

#index8 {
  font-family: sans-serif;
}

#index8 > li > div {
  background-size: 100% 100%;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 1rem auto;
  max-width: 90%;
  padding: 3em 1em;
  position: relative;
  text-align: center;
  width: 400px;
}

#index8 > li > div:first-child:before,
#index8 > li > div:first-child:after {
  bottom: 1.5rem;
  box-shadow: 0 1.5rem 0.8rem rgba(0, 0, 0, 0.8);
  content: "";
  height: 4rem;
  position: absolute;
  width: 50%;
  z-index: -2;
}

#index8 > li > div:first-child:before {
  left: 0.5rem;
  transform: rotate(-5deg);
}

#index8 > li > div:first-child:after {
  right: 0.5rem;
  transform: rotate(5deg);
}

#index8 > li > div:nth-child(2):before,
#index8 > li > div:nth-child(2):after {
  border-radius: 50%;
  content: "";
  height: 4rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 80%;
  z-index: -2;
}

#index8 > li > div:nth-child(2):before {
  box-shadow: 0 -1.5rem 0.8rem rgba(0, 0, 0, 0.8);
  top: 1.3rem;
}

#index8 > li > div:nth-child(2):after {
  box-shadow: 0 1.5rem 0.8rem rgba(0, 0, 0, 0.8);
  bottom: 1.3rem;
}

</style>
