import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";
import axios from "axios";


export default class hole {
    constructor() {
        this.http = new HttpHandler();
    }
    sentMsg(data, type) {
        return this.http.post(`/api/treeHole/sentMsg/${type}`, data, HttpConstant.APPLICATION_JSON_REQ);
    }
    getMsgDataList(data, type) {
        return this.http.get(`/api/treeHole/getMsg/${type}`, data, HttpConstant.MUL_FORM_REQ);
    }

    getTreeHoleS(){
        return this.http.get(`/api/treeHole/getTreeHoles`, null, HttpConstant.MUL_FORM_REQ);
    }
}